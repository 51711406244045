












import Vue from 'vue'

export default Vue.extend({
  props: {
    inverseColors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logo(): string {
      return this.inverseColors ? 'logo-white' : 'logo-color'
    },
  },
})
