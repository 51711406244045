












export default {
  name: 'BaseText',

  props: {
    size: {
      type: String,
      default: 'medium',
      validator: (val: string) => ['small', 'medium', 'large'].includes(val),
    },
    tone: {
      type: String,
      default: 'gray-dark',
      validator: (val: string) =>
        ['gray-dark', 'gray-darker', 'white-base'].includes(val),
    },
    align: {
      type: String,
      default: 'left',
      validator: (val: string) => ['left', 'center', 'right'].includes(val),
    },
  },
}
