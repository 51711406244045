import { Plugin } from '@nuxt/types'
import { GA4Params, UniversalGAParams } from 'types/gtm'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const pushGtmEventPlugin: Plugin = ({ $gtm }, inject) => {
  function isGoogleConsentAccepted() {
    let consent = false
    window.didomiOnReady = window.didomiOnReady || []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.didomiOnReady.push((didomi: any) => {
      consent = didomi.getUserConsentStatusForVendor('google')
    })
    return consent
  }

  inject('pushGtmEvent', (params: UniversalGAParams) => {
    if (isGoogleConsentAccepted()) {
      $gtm.push({
        event: 'gaEvent',
        ...params,
      })
    }
  })

  inject('pushGtm4Event', (params: GA4Params) => {
    if (isGoogleConsentAccepted()) {
      $gtm.push({
        application: 'cambiarcompanialuz',
        ...params,
      })
    }
  })
}

export default pushGtmEventPlugin
