import Vue from 'vue'
import { VueConstructor } from 'vue/types'

const responsive = {
  install(vue: VueConstructor) {
    vue.prototype.$responsive = vue.observable({ isMobile: true })
    if (typeof window === 'undefined') {
      return
    }

    checkIsMobile()

    window.addEventListener('resize', () => checkIsMobile(), { passive: true })

    function checkIsMobile() {
      vue.prototype.$responsive.isMobile = window.innerWidth <= 900
    }
  },
}

Vue.use(responsive)
