




















import Vue from 'vue'

export default Vue.extend({
  props: {
    inverseColors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenuMobile: false,
    }
  },
})
