import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=0d9f960c&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&lang=ts&"
export * from "./TheHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=0d9f960c&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d9f960c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandLogo: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BrandLogo.vue').default,NavigationMenu: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/TheHeader/NavigationMenu/NavigationMenu.vue').default})
