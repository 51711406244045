










import Vue from 'vue'

export default Vue.extend({
  name: 'HomeLayout',
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      htmlAttibute: { ...i18nHead.htmlAttrs },
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.baseUrl}${this.$route.path}`,
        },
      ],
    }
  },
})
