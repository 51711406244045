





























import Vue from 'vue'
import { FooterPageLink } from '~/types/page-link'

export default Vue.extend({
  name: 'TheFooterLegalSection',

  computed: {
    companyInfo() {
      return this.$i18n.t('companyName', { year: new Date().getFullYear() })
    },
    pageLinks(): FooterPageLink[] {
      return this.$t('pageLinks') as unknown as FooterPageLink[]
    },
  },
})
