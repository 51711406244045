//
//
//
//
//
//

export default {
  name: 'BaseStack',
  props: {
    space: {
      type: String,
      default: 'medium',
      validator: (s) =>
        ['small', 'medium', 'large', 'xlarge', '3x-large', '5x-large'].includes(
          s
        ),
    },
  },
}
