type Env = 'production' | 'staging' | 'development'

type PublicRuntimeConfig = {
  assetsUrl: string
  baseUrl: string
  datadogEnabled: boolean
  gtmId: string
  isProduction: boolean
}

type PublicRuntimeConfigs = {
  [key in Env]: PublicRuntimeConfig
}

const env = (process.env.ENV as Env) || 'development'

const publicRuntimeConfigs: PublicRuntimeConfigs = {
  development: {
    assetsUrl: 'https://assets.holaluz.com',
    baseUrl: 'http://localhost:3000',
    datadogEnabled: false,
    gtmId: 'GTM-KSK5F2L',
    isProduction: false,
  },
  production: {
    assetsUrl: 'https://assets.holaluz.com',
    baseUrl: 'https://cambiarcompanialuz.com',
    datadogEnabled: true,
    gtmId: 'GTM-KSK5F2L',
    isProduction: true,
  },
  staging: {
    assetsUrl: 'https://assets.holaluz.com',
    baseUrl: 'https://multidominio.netlify.app',
    datadogEnabled: false,
    gtmId: 'GTM-KSK5F2L',
    isProduction: false,
  },
}

export const publicRuntimeConfig = publicRuntimeConfigs[env]
