import { datadogRum } from '@datadog/browser-rum'
import { publicRuntimeConfig } from '../../public-runtime.config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const setupDatadog = () => {
  if (publicRuntimeConfig.datadogEnabled) {
    try {
      datadogRum.init({
        applicationId: '49ad042b-5631-46a8-9215-ae88d14bbfe6',
        clientToken: 'pubee8efcce931b4fb4a28c99cf92b76e6e',
        site: 'datadoghq.eu',
        service: 'multidomain',
        sessionSampleRate: 100,
        defaultPrivacyLevel: 'allow',
        allowedTracingOrigins: [/https:\/\/.*\.holaluz\.com/],
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
      })

      if (publicRuntimeConfig.isProduction)
        datadogRum.startSessionReplayRecording()
    } catch (error) {
      if (!publicRuntimeConfig.isProduction)
        // eslint-disable-next-line no-console
        console.error('Error loading datadog tracking', error)
    }
  } else if (!publicRuntimeConfig.isProduction)
    // eslint-disable-next-line no-console
    console.info('Datadog tracking DISABLED')
}

const initDatadog = () => {
  window.didomiOnReady = window.didomiOnReady || []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.didomiOnReady.push((didomi: any) => {
    const sendEventIfStatusAccepted = () => {
      const consentStatus =
        didomi.getUserConsentStatusForVendor('c:datadog-8JVyykdi')

      if (consentStatus) setupDatadog()
      return consentStatus
    }

    const status = sendEventIfStatusAccepted()
    if (status === undefined) {
      didomi.on('consent.changed', () => sendEventIfStatusAccepted())
    }
  })
}

export default initDatadog
