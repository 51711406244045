























import Vue from 'vue'
import { Category } from 'types/category'

export default Vue.extend({
  props: {
    inverseColors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    categories(): Category[] {
      return this.$i18n.t('categories') as unknown as Category[]
    },
  },
})
