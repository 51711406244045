













import Vue from 'vue'
import throttle from 'lodash/throttle'

export default Vue.extend({
  name: 'TheHeader',
  data() {
    return {
      isBackgroundColored: false,
    }
  },
  computed: {
    modColored(): string {
      return this.isBackgroundColored ? 'the-header--colored' : ''
    },
    color(): string {
      return this.isBackgroundColored ? 'white' : 'colored'
    },
    isHomePage(): boolean {
      return this.$route.fullPath === '/'
    },
  },
  mounted() {
    if (this.isHomePage || this.$responsive.isMobile) {
      this.isBackgroundColored = true
    }
    window.addEventListener('scroll', throttle(this.handleScroll, 300))
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // On mobile background should be always colored
      if (this.$responsive.isMobile) {
        this.isBackgroundColored = true
        return
      }

      const currentScroll = window.pageYOffset
      if (this.isHomePage && currentScroll <= 900) {
        this.isBackgroundColored = true
      }

      if (this.isHomePage && currentScroll > 900) {
        this.isBackgroundColored = false
      }
    },
  },
})
