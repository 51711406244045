import { render, staticRenderFns } from "./sideBar.vue?vue&type=template&id=1a515470&scoped=true&"
import script from "./sideBar.vue?vue&type=script&lang=ts&"
export * from "./sideBar.vue?vue&type=script&lang=ts&"
import style0 from "./sideBar.vue?vue&type=style&index=0&id=1a515470&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a515470",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/TheHeader/TheHeader.vue').default,BaseContainer: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseContainer.vue').default,TheFooter: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/TheFooter/TheFooter.vue').default})
