









import Vue from 'vue'
import { PropOptions } from 'vue/types'
import { NuxtError } from '@nuxt/types'

export default Vue.extend({
  name: 'ErrorPage',
  props: {
    error: {
      type: Object,
      default: () => ({ message: 'Page not found' }),
    } as PropOptions<NuxtError>,
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
})
