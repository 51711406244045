import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=126a02b3&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=ts&"
export * from "./TheFooter.vue?vue&type=script&lang=ts&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=126a02b3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126a02b3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandLogo: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BrandLogo.vue').default,TheFooterLegalSection: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/TheFooter/TheFooterLegalSection.vue').default})
