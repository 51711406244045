








import Vue from 'vue'

export default Vue.extend({
  name: 'HomeLayout',
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
})
